<template>
   <v-row>
      <v-col cols="12" class="px-0">
         <v-row aling="center" justify="start" class="mx-0">
            <v-col class="text-center py-10">
               <v-row class="mt-12" align="center" justify="center">
                  <v-col class="text-center mt-12">
                     <v-icon color="secondary" size="70">mdi-chart-box-plus-outline</v-icon>
                     <span class="d-block subtitle-1">Bienvenido a los dashboards</span>
                     <span class="d-block subtitle-2">Crea un panel para monitorear tus métricas, todo en un solo lugar</span>
                     <v-btn class="ml-2 mt-5" @click="modalDashboard=true" outlined color="secondary" height="34">Crear Dashboard</v-btn>
                  </v-col>
               </v-row>
            </v-col>
         </v-row>
      </v-col>
      <v-dialog v-model="modalDashboard" width="600" persistent no-click-animation overlay-color="overlay">
         <dialog-add-info-dashboard @close="modalDashboard=false" />
      </v-dialog>
   </v-row>
</template>
<script>
import DialogAddInfoDashboard from '@/components/DialogAddInfoDashboard'
export default {
   components: { DialogAddInfoDashboard },
   data: () => ({
      modalDashboard: false
   })
}
</script>